<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div class="rn-page-title-area pt--120 pb--190 bg_image bg_image--1">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title theme-gradient">
                3x3 Technologies: Creating
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="figma"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in figma"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Figma Video"
                          />
                          <a
                            @click="index = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Creating With:</span>
                        <h2 class="heading-title">
                          <a href="https://www.figma.com/" target="_blank"
                            >Figma</a
                          >
                        </h2>
                        <p class="description">
                          Figma is a browser-based UI and UX design application
                          with excellent design, prototyping, and
                          code-generation tools. It's currently (arguably) the
                          industry's leading interface design tool, with robust
                          features which support teams working on every phase of
                          the design process.
                        </p>
                        <p class="description">
                          The great thing about Figma is they have a free tier
                          that allows anyone to jump right in. This creation
                          tool is helpful for many things before developing a
                          website, mobile app, or just brainstorming. This
                          allows students to visually create a working prototype
                          that operates just like their website or mobile app
                          would. It gives them a chance to see what things look
                          like and make any changes before programming it or
                          sending it to a programmer to start the development.
                        </p>
                        <p class="description">
                          Figma is a creation tool specific to those who are
                          interested in designing and prototyping websites. This
                          means the target audience is digital media, user
                          experience, user design, and programming students.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--20">
                      <p class="description">
                        Figma is a tool that has a simple user interface but
                        some advanced options, which means a younger audience
                        could jump into it and start prototyping or designing.
                        Even though it has a simple interface and is easy to
                        jump into, you need to understand the user experience to
                        use the application properly. That is why I would
                        recommend this to high school students to college who
                        are interested in computer science or user experience.
                      </p>
                      <p class="description">
                        I believe Figma promotes and supports the intended
                        learning outcomes that are expected in the user
                        experience and computer science realm. Figma supports
                        the learning outcomes because it allows students to show
                        their understanding of usefulness, usability,
                        findability, credibility, accessibility, desirability,
                        and evaluability. These are all important outcomes that
                        we look for when a student is learning about the user
                        experience. Figma also supports the learning outcomes
                        for design by allowing students to show their
                        understanding of marketing/communication design,
                        interactive design, page layout, typography, and color
                        theory. With all these learning outcomes between UX/UI
                        students can successfully meet their goals. Figma is
                        also a tool used in the industry so students will be
                        prepared by using a systemic approach. This is great for
                        college students who are graduating and looking for a
                        UX/UI job because they will already have the skillset
                        and knowledge of the tool when jumping into a new
                        position.
                      </p>
                      <p>
                        Below is a YouTube playlist that demonstrates the
                        significant areas of using Figma. This includes
                        exploring ideas, creating designs, prototyping, and
                        handing off.
                      </p>
                      <p>
                        <iframe
                          width="100%"
                          height="600"
                          src="https://www.youtube.com/embed/videoseries?list=PLJ0ErwuErH89nvceQG7mCmgmE8kN8pZkh"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="1"
                    order-md="1"
                    order-lg="2"
                  >
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="vscode"
                        :index="vsindex"
                        @close="vsindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in vscode"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="VSCode Video"
                          />
                          <a
                            @click="vsindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col
                    lg="6"
                    md="12"
                    cols="12"
                    order="2"
                    order-md="2"
                    order-lg="1"
                  >
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Creating With:</span>
                        <h2 class="heading-title">
                          <a
                            href="https://code.visualstudio.com/"
                            target="_blank"
                            >VSCode</a
                          >
                        </h2>
                        <p class="description">
                          VSCode (Visual Studio Code) is a customizable code
                          editor supporting development operations like
                          debugging, task running, and version control. It has
                          plugin support to make it customizable to the
                          programmer and allows most languages to be used to
                          develop. It is an all-in-one type of editor that is
                          lightweight, supports all languages, and is
                          customizable. VSCode has become one of the
                          highest-used editors in the industry.
                        </p>
                        <p class="description">
                          VSCode is a free creation tool made by Microsoft with
                          a lot of support from the community. The support from
                          the community brings in plugins that allow users to
                          customize their editor to do what they need. This
                          editor is lightweight, which means it doesn't bog down
                          the user's computer when creating an application.
                          VSCode allows programmers to develop web applications,
                          mobile applications, or desktop applications which
                          makes it the editor of choice because everything can
                          be done in one area instead of having multiple
                          different coding applications to do the same thing.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12" order="3" order-md="3">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        The target audience for VSCode is anyone who wants to
                        program. That means it has a wide age range as young
                        children are learning to program up to college students.
                        It has a straightforward interface that includes a file
                        explorer, where all your programming files are located—a
                        search area to search all files for anything specific.
                        Source control for those who are using version control,
                        using git. It also includes a debugger and easy access
                        to the plugin store to customize the program's
                        functionality. That is what makes it so useful for
                        creation because it can be a simple tool or a more
                        complex tool depending on the student's needs.
                      </p>
                      <p class="description">
                        I have used VSCode in all of my programming classes. It
                        has been a great creation tool because they can take the
                        tool from my class to another class with minimal setup
                        and already understand how the tool works. This is the
                        case even if the language of the course changes. This
                        also allows students to become very familiar with the
                        tool.
                      </p>
                      <p class="description">
                        I believe VSCode promotes and supports the intended
                        learning outcomes that are expected in computer science
                        students who are learning to program. It is a creation
                        tool that allows them to program and get up and running
                        quickly. It allows us to see that students effectively
                        develop different applications, which is the primary
                        outcome we are looking for. Using this as a systemic
                        tool also provides students with the knowledge of the
                        tool to be successful in the industry. The main goal is
                        to give students the skillset to be prepared for the
                        workforce, and I think introducing students to VSCode
                        and continuing working with it does that.
                      </p>
                      <p class="description">
                        Below is a playlist I created for getting VSCode setup
                        and how easy the process is. It also goes into plugins
                        we used for a specific course and how easy it is to get
                        those set up.
                      </p>
                      <p class="description">
                        <iframe
                          src="https://www.kaltura.com/p/1533221/sp/153322100/embedIframeJs/uiconf_id/17034352/partner_id/1533221/widget_id/1_rtmwh25y?iframeembed=true&playerId=kaltura_player_&flashvars[playlistAPI.kpl0Id]=0_vqgfz5py&flashvars[ks]=&&flashvars[imageDefaultDuration]=30&flashvars[localizationCode]=en&flashvars[leadWithHTML5]=true&flashvars[forceMobileHTML5]=true&flashvars[scrubber.sliderPreview]=false&flashvars[nextPrevBtn.plugin]=true&flashvars[sideBarContainer.plugin]=true&flashvars[sideBarContainer.position]=left&flashvars[sideBarContainer.clickToClose]=true&flashvars[chapters.plugin]=true&flashvars[chapters.layout]=vertical&flashvars[chapters.thumbnailRotator]=false&flashvars[streamSelector.plugin]=true&flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&flashvars[dualScreen.plugin]=true&flashvars[playlistAPI.playlistUrl]=https://1533221.mediaspace.kaltura.com/playlist/details/{playlistAPI.kpl0Id}"
                          width="100%"
                          height="600"
                          allowfullscreen
                          webkitallowfullscreen
                          mozAllowFullScreen
                          allow="autoplay *; fullscreen *; encrypted-media *"
                          sandbox="allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
                          frameborder="0"
                          title="Kaltura Player"
                        ></iframe>
                      </p>
                    </div>
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="12" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="ai"
                        :index="aiindex"
                        @close="aiindex = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in ai"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="Figma Video"
                          />
                          <a
                            @click="aiindex = imageIndex"
                            class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--10">
                      <div class="section-title">
                        <span class="subtitle">Creating With:</span>
                        <h2 class="heading-title">
                          <a
                            href="https://www.adobe.com/products/illustrator.html"
                            target="_blank"
                            >Adobe Illustrator</a
                          >
                        </h2>
                        <p class="description">
                          Adobe Illustrator is an industry-standard vector
                          graphics application that lets you create logos,
                          icons, drawings, typography, and complex illustrations
                          for any medium. It allows you to capture creativity
                          with shapes, colors, effects, and typography. It
                          allows for you to create scalable vector graphics
                          which can be used on any type of device for opitmal
                          resolution.
                        </p>
                        <p class="description">
                          Adobe Illustrator is not a free tool, but it has
                          educational pricing, and our university has a license
                          for students for Creative Cloud. Adobe Illustrator has
                          been around since 1987, so it has a large community
                          for support. Illustrator can be easy to jump into and
                          get started but can create as complex items as needed
                          with a student's creativity.
                        </p>
                        <p class="description">
                          The target audience for Adobe Illustrator is for those
                          who are creative and want to create their items for
                          their websites, animations, or infographics. It allows
                          students to create their visions digitally and use
                          them in all sorts of different mediums. I would
                          recommend any high school student to college student
                          to learn this application which is interested in
                          multimedia design.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30 pt--30">
                      <p class="description">
                        I have used Adobe Illustrator in my User Experience and
                        User Interface courses. I chose Adobe Illustrator
                        because it allows students to create SVG's. SVG's are
                        important because there are many different types of
                        technologies that we make content for. Using SVG allows
                        the content to adapt to any device, no matter how big or
                        small the screen. That's why Adobe Illustrator is great
                        for those who are going into the media design realm.
                        It's not just limited to SVG, but you can also create
                        infographics, logos, icons, and typography, which are
                        significant pieces of developing applications,
                        especially for the web.
                      </p>
                      <p class="description">
                        I believe Adobe Illustrator promotes and supports the
                        intended learning outcomes for students learning design
                        and programming. Using Illustrator allows students to
                        create their vision of anything and implement it into
                        their specific medium. Giving the student the tool to
                        develop an idea plays a significant role in seeing it in
                        action. They can create a logo and implement it into
                        their website and see it first-hand. The outcomes we see
                        here are that students can communicate their design and
                        apply it to another medium they are working with.
                        Illustrator prepares students for digital media and
                        gives them the knowledge of the tool that the industry
                        uses.
                      </p>
                      <p class="description">
                        Below is an in-depth YouTube playlist for Adobe
                        Illustrator that covers all the tools and has examples
                        to get new students up and going in no time.
                      </p>
                      <p class="description">
                        <iframe
                          width="100%"
                          height="600"
                          src="https://www.youtube.com/embed/videoseries?list=PLAgST2_b_8phf6OXaA28mAM4SuWfoHtNr"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                        ></iframe>
                      </p></div
                  ></v-col>
                </v-row>
                <div class="portfolio-details-btn mt--30">
                  <router-link class="btn-default btn-border" to="/LDTE5020"
                    >Back to LDTE 5020</router-link
                  >
                </div>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <Footer />
  </div>
</template>

<script>
import feather from "feather-icons";
import Header from "../../components/header/HeaderThree";
import Footer from "../../components/footer/FooterTwo";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/logo.png"),
      figma: [
        {
          thumb: require("../../assets/images/whatisfigma.jpg"),
          src: "https://youtu.be/Cx2dkpBxst8",
        },
      ],
      vscode: [
        {
          thumb: require("../../assets/images/vscode.jpg"),
          src: "https://youtu.be/S320N3sxinE",
        },
      ],
      ai: [
        {
          thumb: require("../../assets/images/ai.jpg"),
          src: "https://youtu.be/os2UxGhnd4E",
        },
      ],
      index: null,
      vsindex: null,
      aiindex: null,
    };
  },

  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  },
};
</script>
<style scoped>
.v-application a {
  color: black !important;
}
</style>
